/*
 * @Description: 
 * @Version: 2.0
 * @Date: 2020-06-15 14:12:37
 * @LastEditors: *
 * @LastEditTime: 2020-06-29 13:59:23
 */ 
import "@/js/common.js";
import "@/scss/index.scss";
import Swiper from "swiper";
import "@/component/header/index.js";
import "@/component/footer/index.js";

$(document).ready(function () {
  $('.header').tap(function () {
    event.stopPropagation()
  });
  $(document).tap(function(){
    $('.header ul').removeClass('open')
  })
  var mySwiper1 = new Swiper(".scrollTab .swiper-container", {
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 10,
  });

  var mySwiper2 = new Swiper(".tabSwitch .swiper-container", {
    autoplay: false,
    slidesPerView: "auto",
    centeredSlides: true,
    autoHeight: true,
  });

  var mySwiper3 = new Swiper(".singleBanner .swiper-container", {
    slidesPerView: "auto",
    centeredSlides: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: false,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + "</span>";
      },
    },
  });

  $(".tab-header-item").tap(function () {
    var $this = $(this);
    var index = $this.index();
    if ($this.hasClass("active")) {
      return;
    } else {
      $(".tab-header-item").removeClass("active");
      $this.addClass("active");
      mySwiper2.slideTo(index, 1000, false);
    }
  });
});
